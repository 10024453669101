import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Descriptions, Select, List, message } from 'antd';
import axios from 'axios';
import config from '../../../config';
const { Option } = Select;

class UserCreate extends Component {
    state = {
        key: 0,
        loading: false,
        data: {}, 
        PLO: [], 
        PSO: [], 
        PEO: [],
        DEPARTMENT: '',
    };

    handleChangeSelect = (value) => {
        console.log("Department selected:", value);  // Log department value to check

        this.setState({ DEPARTMENT: value.value });

        let data = {
            info: this.props.common.user,
            key: config.key,
            dept: value.value
        };

        axios.post(config.curriculaserver + '/curricula/batchcoordinator/po/view', data)
            .then(res => {
                console.log("Response:", res);  // Log the response to check data structure
                if (res.data.Status === 1) {
                    // Update state with fetched data or reset if not available
                    this.setState({
                        PLO: res.data.result.PLO || [],  // Ensure it's an empty array if no PLO data
                        PSO: res.data.result.PSO || [],  // Same for PSO
                        PEO: res.data.result.PEO || [],  // Same for PEO
                        loading: false,
                    });
                    message.success('Information Loaded!');
                } else {
                    // Reset if data not found
                    this.setState({
                        PLO: [],
                        PSO: [],
                        PEO: [],
                        loading: false,
                    });
                    message.error('Information Not Found!');
                }
            })
            .catch(error => {
                console.error("Error fetching data:", error);  // Catch any errors
                this.setState({ loading: false });
                message.error('An error occurred while fetching data.');
            });
    };

    render() {
        let { PLO, PSO, PEO } = this.state;

        return (
            <>
                <div style={{ padding: '0px', minHeight: "100%", marginTop: "20px" }}>
                    <Descriptions bordered column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
                        <Descriptions.Item label={"Departments List"}>
                            <Select
                                labelInValue
                                style={{ width: "100%" }}
                                onChange={this.handleChangeSelect}
                                placeholder="Select Department"
                            >
                                {config.departments.map((item, value) => (
                                    item !== "ALL" ? <Option key={value} value={item}>{item}</Option> : ''
                                ))}
                            </Select>
                        </Descriptions.Item>

                        {PLO.length > 0 ? (
                            <Descriptions.Item label={"Program Outcomes"}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={PLO}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={"PO" + (index + 1)}
                                                description={item}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Descriptions.Item>
                        ) : (
                            <Descriptions.Item label={"Program Outcomes"}></Descriptions.Item>
                        )}

                        {PSO.length > 0 ? (
                            <Descriptions.Item label={"Program Specific Outcomes"}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={PSO}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={"PSO" + (index + 1)}
                                                description={item}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Descriptions.Item>
                        ) : (
                            <Descriptions.Item label={"Program Specific Outcomes"}></Descriptions.Item>
                        )}

                        {PEO.length > 0 ? (
                            <Descriptions.Item label={"Program Educational Outcomes"}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={PEO}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={"PEO" + (index + 1)}
                                                description={item}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Descriptions.Item>
                        ) : (
                            <Descriptions.Item label={"Program Educational Outcomes"}></Descriptions.Item>
                        )}
                    </Descriptions>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    common: state.common
});

export default withRouter(connect(mapStateToProps, {})(UserCreate));