import React, { Component } from 'react'
import { Row, Col,Card,List, Descriptions, Avatar, Spin, message,Input, Checkbox, Button, Result } from 'antd';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import config from '../../config';
import ViewPO from '../admin/ProgramOutcomes/ViewPO';

class ProgramObjective extends Component {

    state = {
        loading: true, open: false,
        PLO: [], PSO: [], PEO: [],
        error: 0,
    }

    async componentDidMount() {
        await this.POget();
        this.setState({ loading: false, open: true })
    }

    POget = () => {
        let DEPT = this.props.common.user.DEPARTMENT;

        let data = {
            info: this.props.common.user,
            key: config.key,
            dept: DEPT
        };

        axios.post(config.curriculaserver + '/curricula/batchcoordinator/po/view', data)
            .then(async res => {
               // console.log(res.data)
                if (res.data.Status === 1) {
                    await this.setState({ PLO: res.data.result.PLO, PSO: res.data.result.PSO, PEO: res.data.result.PEO, loading: false });
                    await message.success('Information Loaded !');
                }
                else {
                    await this.setState({ PLO: res.data.default.PLO, PSO: res.data.default.PSO, PEO: res.data.default.PEO, loading: false });
                }
            })
    }

    render() {
        let { open, PEO, PSO, PLO } = this.state;
        return (
            <div>
                <Spin spinning={this.state.loading}>
                    {
                        open ?
                            (<Descriptions
                                bordered
                                column={{ sm: 1, md: 1, lg: 1, xs: 1 }}
                                size="small"
                                className="padding-1 justify">
                                <Descriptions.Item label="Department" span={2}>
                                    {this.props.common.user.DEPARTMENT}
                                </Descriptions.Item>
                                <Descriptions.Item label={"Programme Educational Objectives (PEOs)"} span={2}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={PEO}
                                        renderItem={(item, val) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<Avatar style={{ fontSize: "12px" }} size={40}>PEO {val + 1}</Avatar>}
                                                    description={item}
                                                />
                                            </List.Item>
                                        )}
                                    />

                                </Descriptions.Item>
                                <Descriptions.Item label={"Programme Outcomes (PO)"} span={2}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={PLO}
                                        renderItem={(item, val) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<Avatar style={{ fontSize: "12px" }} size={40}>PO {val < 9 ? 0 : ''}{val + 1}</Avatar>}
                                                    description={item}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label={"Programme Specific Outcomes (PSO)"} span={2}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={PSO}
                                        renderItem={(item, val) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={<Avatar style={{ fontSize: "12px" }} size={40}>PSO {val + 1}</Avatar>}
                                                    description={item}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Descriptions.Item>
                            </Descriptions>) : ''
                    }
                </Spin>
                <Spin spinning={this.state.loading}>

<Row gutter={[16, 16]} justify="start">
    <Col xs={24} md={12} >
        <Card
            title="DIPLOMA IN CHRISTIAN MINISTRIES (Three-years program) "
            bordered={true}
            className='justify'
        >
            The Diploma of Christian Ministry at E.W.B.C. provides an intentional introductory body of knowledge and skills in the discipline area of 21st century Ministry. The program is designed to produce competent graduates who can apply integrated technical and theoretical ministry concepts in a broad range of contexts from a Christian worldview and embody godly integrity, professional knowledge, and social skills, making a positive contribution to the community. 

        </Card>
    </Col>
    <Col xs={24} md={12} >
        <Card
            title="Duration of the program"
            bordered={true}
            className='justify'
        >
            For a successful qualification of an E.W.B.C. student, the prospective student must complete all the required courses and necessary hours to fulfill the mandatory minimum credit hours of study. Therefore, a full-time student at E.W.B.C. will complete this program in 3 years, translating to 6 semesters.<br /> <br />
        </Card>
    </Col>

    <Col xs={24}>
        <Card
            title="PO Evaluator"
            bordered={true}
            className='justify'
        >
            All the courses together must cover all the POs (and PSOs). For a course we map the COs to POs through the CO-PO matrix and to PSOs through the CO-PSO matrix as shown below. The various correlation levels are:
            <br />
            1 – Slight (Low) Correlation
            <br />
            2 – Moderate (Medium) Correlation
            <br />
            3 – Substantial (High) Correlation
            <br />
            0 – indicates there is no Correlation
        </Card>
    </Col>
</Row>

<br />

{
    this.state.error === 0 ?
        (
            <Descriptions
                bordered={true}
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
                <Descriptions.Item label="CO1">
                    <Row align="center" justify="center">
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Input id="CLO1" value={this.state.CLO1} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                            </Row>
                        </Col>

                        <Col xs={24} className="mt-1">
                            <Row gutter={[16, 32]}>
                                <Checkbox.Group style={{ width: '100%' }}
                                    //onChange={this.onChangeCheckBox.bind(this, "OC1")} 
                                    value={this.state.OC1}>
                                    {this.FunctionCheckboxGroup(1)}
                                </Checkbox.Group>
                            </Row>
                        </Col>
                    </Row>
                </Descriptions.Item>

                <Descriptions.Item label="CO2">
                    <Row align="center" justify="center">
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Input id="CLO2" value={this.state.CLO2} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                            </Row>
                        </Col>

                        <Col xs={24} className="mt-1">
                            <Row gutter={[16, 16]}>
                                <Checkbox.Group style={{ width: '100%' }} //onChange={this.onChangeCheckBox.bind(this, "OC2")} 
                                    value={this.state.OC2}>
                                    {this.FunctionCheckboxGroup(2)}
                                </Checkbox.Group>
                            </Row>
                        </Col>
                    </Row>
                </Descriptions.Item>

                <Descriptions.Item label="CO3">
                    <Row align="center" justify="center">
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Input id="CLO3" value={this.state.CLO3} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                            </Row>
                        </Col>

                        <Col xs={24} className="mt-1">
                            <Row gutter={[16, 16]}>
                                <Checkbox.Group style={{ width: '100%' }}
                                    //onChange={this.onChangeCheckBox.bind(this, "OC3")} 
                                    value={this.state.OC3}>
                                    {this.FunctionCheckboxGroup(3)}
                                </Checkbox.Group>
                            </Row>
                        </Col>
                    </Row>
                </Descriptions.Item>

                <Descriptions.Item label="CO4">
                    <Row align="center" justify="center">
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Input id="CLO4" value={this.state.CLO4} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                            </Row>
                        </Col>

                        <Col xs={24} className="mt-1">
                            <Row gutter={[16, 16]}>
                                <Checkbox.Group style={{ width: '100%' }}
                                    //onChange={this.onChangeCheckBox.bind(this, "OC4")} 
                                    value={this.state.OC4}>
                                    {this.FunctionCheckboxGroup(4)}
                                </Checkbox.Group>
                            </Row>
                        </Col>
                    </Row>
                </Descriptions.Item>

                <Descriptions.Item label="CO5">
                    <Row align="center" justify="center">
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Input id="CLO5" value={this.state.CLO5} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                            </Row>
                        </Col>

                        <Col xs={24} className="mt-1">
                            <Row gutter={[16, 16]}>
                                <Checkbox.Group style={{ width: '100%' }}
                                    //onChange={this.onChangeCheckBox.bind(this, "OC5")} 
                                    value={this.state.OC5}>
                                    {this.FunctionCheckboxGroup(5)}
                                </Checkbox.Group>
                            </Row>
                        </Col>
                    </Row>
                </Descriptions.Item>

                <Descriptions.Item label="CO6">
                    <Row align="center" justify="center">
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Input id="CLO6" value={this.state.CLO6} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                            </Row>
                        </Col>

                        <Col xs={24} className="mt-1">
                            <Row gutter={[16, 16]}>
                                <Checkbox.Group style={{ width: '100%' }}
                                    //onChange={this.onChangeCheckBox.bind(this, "OC6")} 
                                    value={this.state.OC6}>
                                    {this.FunctionCheckboxGroup(6)}
                                </Checkbox.Group>
                            </Row>
                        </Col>
                    </Row>
                </Descriptions.Item>

                <Descriptions.Item label="Action">
                    <Button type="primary" onClick={this.onClickAdd}>Submit</Button>
                    {/* <Button className="ml-1" danger onClick={() => { this.setState({ showDrawer: 1 }) }}>View Detailed Program Outcomes</Button> */}
                </Descriptions.Item>
                <Descriptions.Item label="View Outcomes">
                                       
                                       <ViewPO/> 
                                  </Descriptions.Item>

            </Descriptions>
        ) : <Result
            status="404"
            title="Course Structure is Empty"
            subTitle="Department information are empty"
        />

}
</Spin>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(ProgramObjective));