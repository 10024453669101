const data = {
    course_Structure: [
        "Basic Sciences",
        "Professional Core",
        "Professional Elective",
        "Open Elective"],
    PROGRAM_OUTCOMES: {
        PLO1: " ",
        PLO2: "",
        PLO3: " ",
        PLO4: " ",
        PLO5: " ",
        PLO6: " ",
        PLO7: " ",
        PLO8: " ",
        PLO9: " ",
        PLO10: " ",
        PLO11: " ",
        PLO12: " "
    },
    OUTCOMES: [
        {
            
            DEPT: "DEFAULT",
            PEO: {
                PEO1: '',
                PEO2: "",
                PEO3: "",
            },
            PLO: {
                PLO1: "",
                PLO2: "",
                PLO3: "",
                PLO4: "",
                PLO5: "",
                PLO6: "",
                PLO7: "",
                PLO8: "",
                PLO9: "",
                PLO10: "",
                PLO11: "",
                PLO12: "",
            },
            PSO: {
                PSO1: "",
                PSO2: "",
                PSO3: "",
            }
        }
    ]

};
export default data;