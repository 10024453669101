import React, { Component } from 'react'
import { Row, Col, Input, Checkbox, Radio, Descriptions, Result, Button, message, Spin, Tooltip, Drawer, List, Avatar, Card } from 'antd';
import { connect } from "react-redux";
import axios from 'axios';
import config from '../../../config';
import { withRouter } from "react-router-dom";
import ViewPO from '../ProgramOutcomes/ViewPO';

const page_name = "course_learning_objective";


class CourseLearningObjective extends Component {
    state = {
        COURSE_CODE: this.props.COURSE_CODE, COURSE_NAME: this.props.COURSE_NAME, loading: true,
        CLO1: '', CLO2: '', CLO3: '', CLO4: '', CLO5: '', CLO6: '',
        OC1: [], OC2: [], OC3: [], OC4: [], OC5: [], OC6: [],
        OCW1: [], OCW2: [], OCW3: [], OCW4: [], OCW5: [], OCW6: [],
        error: 0,
        PBOJ: {}, PROOBJECTIVES: [], PSO: {}, showDrawer: 0
    }

    async componentDidMount() {
        await this.getCourseLO(this.state.COURSE_CODE);
        this.setState({ loading: false });
    }

    async UNSAFE_componentWillReceiveProps(props) {
        await this.setState({ loading: true, error: 0 });
        await this.setState({ COURSE_CODE: props.COURSE_CODE, COURSE_NAME: props.COURSE_NAME })
        await this.getCourseLO(props.COURSE_CODE);
        this.setState({ loading: false });
    }

    getCourseLO = (COURSE_CODE) => {

        if (COURSE_CODE) {
            let sendData = {
                data: {
                    COURSE_CODE: COURSE_CODE,
                    title: page_name
                },
                key: config.key
            };

            axios.post(config.curriculaserver + '/curricula/admin/course/getcourseinfo', sendData)
                .then(async res => {
                    if (res.data.Status === 1) {
                        let record = res.data.selectedCourseData;
                        if (record)
                            Object.entries(record).map(([key, value]) => this.setState({ [key]: value }));
                        else
                            this.setState({
                                CLO1: '', CLO2: '', CLO3: '', CLO4: '', CLO5: '', CLO6: '',
                                OC1: [], OC2: [], OC3: [], OC4: [], OC5: [], OC6: [],
                                OCW1: [], OCW2: [], OCW3: [], OCW4: [], OCW5: [], OCW6: [],
                            });

                        if (res.data.dept) {
                            let DEPT = res.data.dept;

                            let data = {
                                info: this.props.common.user,
                                key: config.key,
                                dept: DEPT
                            };

                            await axios.post(config.curriculaserver + '/curricula/batchcoordinator/po/view', data)
                                .then(async res => {
                                    let tempPBOJ = {}, PROOBJECTIVES = [], PLO = [], PSO = [];
                                    if (res.data.Status === 1) {
                                        PLO = res.data.result.PLO; PSO = res.data.result.PSO;
                                    }
                                    else {
                                        PLO = res.data.default.PLO; PSO = res.data.default.PSO;
                                    }

                                    tempPBOJ.PLO1 = PLO[0];
                                    tempPBOJ.PLO2 = PLO[1];
                                    tempPBOJ.PLO3 = PLO[2];
                                    tempPBOJ.PLO4 = PLO[3];
                                    tempPBOJ.PLO5 = PLO[4];
                                    tempPBOJ.PLO6 = PLO[5];
                                    tempPBOJ.PLO7 = PLO[6];
                                    tempPBOJ.PLO8 = PLO[7];
                                    tempPBOJ.PLO9 = PLO[8];
                                    tempPBOJ.PLO10 = PLO[9];
                                    tempPBOJ.PLO11 = PLO[10];
                                    tempPBOJ.PLO12 = PLO[11];
                                    tempPBOJ.PLO13 = PSO[0];
                                    tempPBOJ.PLO14 = PSO[1];
                                    tempPBOJ.PLO15 = PSO[2];

                                    PROOBJECTIVES = PLO;
                                    PROOBJECTIVES.push(PSO[0]);
                                    PROOBJECTIVES.push(PSO[1]);
                                    PROOBJECTIVES.push(PSO[2]);
                                    await this.setState({ PBOJ: tempPBOJ, PROOBJECTIVES: PROOBJECTIVES });
                                })
                        }
                        else
                            this.setState({ error: 1 });
                    }
                    this.setState({ loading: false });
                });
        }
        else
            message.error("Course Not Selected");
    }

    handleChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onChangeCheckBox = (name, checkedValues) => {
        this.setState({ [name]: checkedValues });
    }
    onChangeRadio = (name, PLO, e) => {
        let arr = [...this.state["OCW" + name]];
        let PLOarr = [...this.state["OC" + name]];
        if (e.target.value > 0) {
            if (!PLOarr.includes(PLO))
                PLOarr.push(PLO);
            arr = arr.filter(x => x.oc !== PLO);
            arr.push({ oc: PLO, weight: e.target.value });
        }
        else if (e.target.value === 0) {
            PLOarr = PLOarr.filter(e => e !== PLO);
            arr = arr.filter(x => x.oc !== PLO);
        }
        this.setState({ ["OCW" + name]: arr, ["OC" + name]: PLOarr });
    }

    FunctionCheckboxGroup = (id) => {
        let PBOJECTIVES = this.state.PBOJ;
        let arr = [...this.state["OCW" + id]];
        var result = [];
        arr.map(x => result[x.oc] = x.weight);

        let PLO, html = [], PLONAME;
        for (let i = 1; i <= 15; i++) {
            PLO = "PLO" + i;
            i < 10 ? PLONAME = "PO 0" + i : PLONAME = "PO " + i;
            /*     html.push(<Col span={24}><Tooltip key={PLO + id} title={PBOJECTIVES[PLO]}>
                     <Checkbox className="ml-2 mt-1" value={PLO}>{PLO}</Checkbox>
                 </Tooltip></Col>);
                 */
            html.push(<Col key={PLO + id} span={24}>
                <Descriptions bordered size="small" className="objective-border"
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
                    <Descriptions.Item label={<Tooltip key={PLO + id} title={PBOJECTIVES[PLO]}><Checkbox className="ml-2 mt-1" value={PLO}>{PLONAME}</Checkbox></Tooltip>}>

                        <Radio.Group name="radiogroup" onChange={this.onChangeRadio.bind(this, id, PLO)}
                            value={result[PLO]}
                        >
                            <Radio key={id + PLO + "1"} id='H' value={3}>3 - High</Radio>
                            <Radio key={id + PLO + "2"} id="M" value={2}>2 - Medium</Radio>
                            <Radio key={id + PLO + "3"} id="L" value={1}>1 - Low</Radio>
                            <Radio key={id + PLO + "4"} id="N" value={0}>0 - None</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                </Descriptions>
            </Col>)

        }
        return html;
    }

    onClickAdd = async () => {
        let { COURSE_CODE, CLO1, CLO2, CLO3, CLO4, CLO5, CLO6, OC1, OC2, OC3, OC4, OC5, OC6, OCW1, OCW2, OCW3, OCW4, OCW5, OCW6 } = this.state;

        let CLO = {
            CLO1: CLO1,
            CLO2: CLO2,
            CLO3: CLO3,
            CLO4: CLO4,
            CLO5: CLO5,
            CLO6: CLO6
        };

        let OC = {
            OC1: OC1,
            OC2: OC2,
            OC3: OC3,
            OC4: OC4,
            OC5: OC5,
            OC6: OC6,
        }
        let OCW = {
            OC1: OCW1,
            OC2: OCW2,
            OC3: OCW3,
            OC4: OCW4,
            OC5: OCW5,
            OC6: OCW6,
        }

        let flag = 1;
        let error = [];
        Object.keys(CLO).forEach(function (key) {
            if (!CLO[key]) {
                flag = 0;
                error.push(0)
            }
        });

        Object.keys(OC).forEach(function (key) {
            if (OC[key].length < 1) {
                flag = 0;
                error.push(0)
            }
        });
        Object.keys(OCW).forEach(function (key) {
            if (OC[key].length !== OCW[key].length) {
                flag = -1;
                error.push(-1)
            }
            else {
                OCW[key].map(x => !OC[key].includes(x.oc) ? error.push(-1) : '')
            }
        });

        if (flag === 0 || error.includes(0)) {
            message.error('Invalid Entry!');
            return false;
        }

        if (flag === -1 || error.includes(-1)) {
            message.error('Weightage is not assigned properly!');
            return false;
        }

        let data = {
            CLO1: CLO1,
            CLO2: CLO2,
            CLO3: CLO3,
            CLO4: CLO4,
            CLO5: CLO5,
            CLO6: CLO6,
            OC1: OC1,
            OC2: OC2,
            OC3: OC3,
            OC4: OC4,
            OC5: OC5,
            OC6: OC6,
            OCW1: OCW1,
            OCW2: OCW2,
            OCW3: OCW3,
            OCW4: OCW4,
            OCW5: OCW5,
            OCW6: OCW6,
        }

        let sendData = {
            data: { course_learning_objective: data },
            COURSE_CODE: COURSE_CODE,
            key: config.key
        };

        await axios.post(config.curriculaserver + '/curricula/admin/course/addcourseinfo', sendData)
            .then(async res => {
                if (res.data.Status === 1) {
                    message.success(res.data.msg);
                } else
                    message.error(res.data.msg);
            });
    }

    onClose = () => {
        this.setState({ showDrawer: 0 });
    }

    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>

                    <Row gutter={[16, 16]} justify="start">
                        <Col xs={24} md={12} >
                            <Card
                                title="DIPLOMA IN CHRISTIAN MINISTRIES (Three-years program) "
                                bordered={true}
                                className='justify'
                            >
                                The Diploma of Christian Ministry at E.W.B.C. provides an intentional introductory body of knowledge and skills in the discipline area of 21st century Ministry. The program is designed to produce competent graduates who can apply integrated technical and theoretical ministry concepts in a broad range of contexts from a Christian worldview and embody godly integrity, professional knowledge, and social skills, making a positive contribution to the community. 

                            </Card>
                        </Col>
                        <Col xs={24} md={12} >
                            <Card
                                title="Duration of the program"
                                bordered={true}
                                className='justify'
                            >
                                For a successful qualification of an E.W.B.C. student, the prospective student must complete all the required courses and necessary hours to fulfill the mandatory minimum credit hours of study. Therefore, a full-time student at E.W.B.C. will complete this program in 3 years, translating to 6 semesters.<br /> <br />
                            </Card>
                        </Col>
                        <Col xs={24} md={12} >
                            <Card
                                title="Course Outcomes (COs)"
                                bordered={true}
                                className='justify'
                            >
                                Statements indicating what a student can do after the successful completion of a course. Every Course leads to some Course Outcomes. The CO statements are defined by considering the course content covered in each module of a course. For every course there may be 5 or 6 COs. The keywords used to define COs are based on Bloom’s Taxonomy.

                            </Card>
                        </Col>
                        <Col xs={24} md={12} >
                            <Card
                                title="Program Outcomes (PO+PSO)"
                                bordered={true}
                                className='justify'
                            >
                                Program outcomes describe what students are expected to know and would be able to do by the time of graduation. These relate to the skills, knowledge, and behaviors that students acquire as they progress through the program.<br /> <br />
                            </Card>
                        </Col>


                        <Col xs={24}>
                            <Card
                                title="PO Evaluator"
                                bordered={true}
                                className='justify'
                            >
                                All the courses together must cover all the POs (and PSOs). For a course we map the COs to POs through the CO-PO matrix and to PSOs through the CO-PSO matrix as shown below. The various correlation levels are:
                                <br />
                                1 – Slight (Low) Correlation
                                <br />
                                2 – Moderate (Medium) Correlation
                                <br />
                                3 – Substantial (High) Correlation
                                <br />
                                0 – indicates there is no Correlation
                            </Card>
                        </Col>
                    </Row>

                    <br />

                    {
                        this.state.error === 0 ?
                            (
                                <Descriptions
                                    bordered={true}
                                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                                >
                                    <Descriptions.Item label="CO1">
                                        <Row align="center" justify="center">
                                            <Col xs={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Input id="CLO1" value={this.state.CLO1} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                                                </Row>
                                            </Col>

                                            <Col xs={24} className="mt-1">
                                                <Row gutter={[16, 32]}>
                                                    <Checkbox.Group style={{ width: '100%' }}
                                                        //onChange={this.onChangeCheckBox.bind(this, "OC1")} 
                                                        value={this.state.OC1}>
                                                        {this.FunctionCheckboxGroup(1)}
                                                    </Checkbox.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="CO2">
                                        <Row align="center" justify="center">
                                            <Col xs={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Input id="CLO2" value={this.state.CLO2} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                                                </Row>
                                            </Col>

                                            <Col xs={24} className="mt-1">
                                                <Row gutter={[16, 16]}>
                                                    <Checkbox.Group style={{ width: '100%' }} //onChange={this.onChangeCheckBox.bind(this, "OC2")} 
                                                        value={this.state.OC2}>
                                                        {this.FunctionCheckboxGroup(2)}
                                                    </Checkbox.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="CO3">
                                        <Row align="center" justify="center">
                                            <Col xs={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Input id="CLO3" value={this.state.CLO3} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                                                </Row>
                                            </Col>

                                            <Col xs={24} className="mt-1">
                                                <Row gutter={[16, 16]}>
                                                    <Checkbox.Group style={{ width: '100%' }}
                                                        //onChange={this.onChangeCheckBox.bind(this, "OC3")} 
                                                        value={this.state.OC3}>
                                                        {this.FunctionCheckboxGroup(3)}
                                                    </Checkbox.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="CO4">
                                        <Row align="center" justify="center">
                                            <Col xs={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Input id="CLO4" value={this.state.CLO4} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                                                </Row>
                                            </Col>

                                            <Col xs={24} className="mt-1">
                                                <Row gutter={[16, 16]}>
                                                    <Checkbox.Group style={{ width: '100%' }}
                                                        //onChange={this.onChangeCheckBox.bind(this, "OC4")} 
                                                        value={this.state.OC4}>
                                                        {this.FunctionCheckboxGroup(4)}
                                                    </Checkbox.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="CO5">
                                        <Row align="center" justify="center">
                                            <Col xs={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Input id="CLO5" value={this.state.CLO5} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                                                </Row>
                                            </Col>

                                            <Col xs={24} className="mt-1">
                                                <Row gutter={[16, 16]}>
                                                    <Checkbox.Group style={{ width: '100%' }}
                                                        //onChange={this.onChangeCheckBox.bind(this, "OC5")} 
                                                        value={this.state.OC5}>
                                                        {this.FunctionCheckboxGroup(5)}
                                                    </Checkbox.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="CO6">
                                        <Row align="center" justify="center">
                                            <Col xs={24}>
                                                <Row gutter={[16, 16]}>
                                                    <Input id="CLO6" value={this.state.CLO6} onChange={this.handleChange} addonBefore="Upon completion of the course, the learners will be able to" style={{ width: '100%' }} />
                                                </Row>
                                            </Col>

                                            <Col xs={24} className="mt-1">
                                                <Row gutter={[16, 16]}>
                                                    <Checkbox.Group style={{ width: '100%' }}
                                                        //onChange={this.onChangeCheckBox.bind(this, "OC6")} 
                                                        value={this.state.OC6}>
                                                        {this.FunctionCheckboxGroup(6)}
                                                    </Checkbox.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Action">
                                        <Button type="primary" onClick={this.onClickAdd}>Submit</Button>
                                        {/* <Button className="ml-1" danger onClick={() => { this.setState({ showDrawer: 1 }) }}>View Detailed Program Outcomes</Button> */}

                                    </Descriptions.Item>
                                    <Descriptions.Item label="View Outcomes">
                                       
                                         <ViewPO/> 
                                    </Descriptions.Item>

                                </Descriptions>
                            ) : <Result
                                status="404"
                                title="Course Structure is Empty"
                                subTitle="Department information are empty"
                            />

                    }
                </Spin>
                 {
                    this.state.loading === false ?
                        <Drawer
                            title="Program Outcomes"
                            placement="right"
                            onClose={this.onClose}
                            width={456}
                            visible={this.state.showDrawer}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={this.state.PROOBJECTIVES}
                                renderItem={(item, val) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            avatar={<Avatar style={{ fontSize: "12px" }} size={40}>PO{val + 1}</Avatar>}
                                            description={<span className="cText">{item}</span>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Drawer> : ''
                } 
            </>
        )
    }
}

const mapStateToProps = state => ({
    common: state.common
});
export default withRouter(connect(mapStateToProps, {})(CourseLearningObjective));